*,:after,:before {
    box-sizing: border-box
}

body,html {
    margin: 0;
    padding: 0
}

html {
    height: 100%
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: europa,"sans-serif";
    font-size: 16px;
    justify-content: space-between;
}

body * {
    box-sizing: border-box
}