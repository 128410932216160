/* License for Albra from MyFonts */
// @import url("//hello.myfonts.net/count/3c1427");

@font-face {
	font-display: swap;
	font-family: "Albra";
	font-style: normal;
	font-weight: 300;
	src: url("../fonts/albra-light.woff2") format("woff2"),
		url("../fonts/albra-light.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-display: swap;
	font-family: "Albra";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/albra-regular.woff2") format("woff2"),
		url("../fonts/albra-regular.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-display: swap;
	font-family: "Albra";
	font-style: normal;
	font-weight: 500;
	src: url("../fonts/albra-medium.woff2") format("woff2"),
		url("../fonts/albra-medium.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-display: swap;
	font-family: "Albra";
	font-style: normal;
	font-weight: 600;
	src: url("../fonts/albra-semi.woff2") format("woff2"),
		url("../fonts/albra-semi.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-display: swap;
	font-family: "Albra";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/albra-bold.woff2") format("woff2"),
		url("../fonts/albra-bold.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-display: swap;
	font-family: "Albra";
	font-style: normal;
	font-weight: 800;
	src: url("../fonts/albra-black.woff2") format("woff2"),
		url("../fonts/albra-black.woff") format("woff");
}

/* Europa TypeKit License
  europa:
*   - http://typekit.com/eulas/00000000000000003b9b12fa
*   - http://typekit.com/eulas/00000000000000003b9b12fe
*   - http://typekit.com/eulas/00000000000000003b9b12fd
*   - http://typekit.com/eulas/00000000000000003b9b12f9
*   - http://typekit.com/eulas/00000000000000003b9b12fb
*   - http://typekit.com/eulas/00000000000000003b9b12fc
*/
// Europa from TypeKit / Adobe Fonts
// @import url("https://p.typekit.net/p.css?s=1&k=qst3yed&ht=tk&f=15528.15529.15530.17251.17252.17253&a=20452443&app=typekit&e=css");

@font-face {
	font-family: "europa";
	src: url("https://use.typekit.net/af/3e64fb/00000000000000003b9b12fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/3e64fb/00000000000000003b9b12fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
			format("woff"),
		url("https://use.typekit.net/af/3e64fb/00000000000000003b9b12fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
			format("opentype");

	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: "europa";
	src: url("https://use.typekit.net/af/4eabcf/00000000000000003b9b12fd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/4eabcf/00000000000000003b9b12fd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("woff"),
		url("https://use.typekit.net/af/4eabcf/00000000000000003b9b12fd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
			format("opentype");

	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "europa";
	src: url("https://use.typekit.net/af/f3ba4f/00000000000000003b9b12fa/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/f3ba4f/00000000000000003b9b12fa/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("woff"),
		url("https://use.typekit.net/af/f3ba4f/00000000000000003b9b12fa/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
			format("opentype");

	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "europa";
	src: url("https://use.typekit.net/af/a386b3/00000000000000003b9b12f9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/a386b3/00000000000000003b9b12f9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
			format("woff"),
		url("https://use.typekit.net/af/a386b3/00000000000000003b9b12f9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
			format("opentype");

	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: "europa";
	src: url("https://use.typekit.net/af/e32ad9/00000000000000003b9b12fb/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/e32ad9/00000000000000003b9b12fb/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
			format("woff"),
		url("https://use.typekit.net/af/e32ad9/00000000000000003b9b12fb/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
			format("opentype");

	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "europa";
	src: url("https://use.typekit.net/af/a6fa4a/00000000000000003b9b12fc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
			format("woff2"),
		url("https://use.typekit.net/af/a6fa4a/00000000000000003b9b12fc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
			format("woff"),
		url("https://use.typekit.net/af/a6fa4a/00000000000000003b9b12fc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
			format("opentype");

	font-style: italic;
	font-weight: 700;
}

a {
	cursor: pointer;
	text-decoration: none;
	font-weight: bold;

	&:hover {
		text-decoration: underline;
	}
}
