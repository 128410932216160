/*********
POP-UP STYLES
*********/
.popup {
	background-color: rgb(0 0 0 / 50%);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;

	.popup-inner {
		background: #fff;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		max-height: 85vh;
		max-width: 55rem;
		text-align: center;
		width: 100%;

		&.login-popup {
			max-width: 350px;
		}
		.popup-header {
			background-color: $orange-fiery;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px 70px;
			position: relative;
			width: 100%;

			@media screen and (max-width: $screen-sm) {
				padding: 15px 60px 15px 12px;
			}

			* {
				color: #fff;
				font-size: 1.25em;
				margin: 0;
			}

			.popup-close {
				cursor: pointer;
				position: absolute;
				top: 15px;
				right: 15px;

				svg {
					fill: #fff;
				}

				&:hover {
					opacity: 0.7;
				}
			}

			&.no-bg {
				background-color: transparent;

				.popup-close {
					svg {
						fill: #000;
					}
				}
			}

			&.search {
				padding: 0;
			}
		}

		.popup-tabs {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 0.5rem;
			margin: 0;
			padding: 0;
			button {
				display: block;
				text-align: center;
				text-decoration: none;
				background: #ffffff31;
				color: $white;
				border: none;
				border-radius: 2rem;
				font-size: 1rem;
				line-height: 1em;
				padding: 0.5rem 1rem;
				transition: all 0.3s ease-in-out;
				cursor: pointer;

				@media screen and (max-width: $screen-sm) {
					font-size: 0.85rem;
					padding: 0.5rem 0.5rem;
				}

				&:last-of-type {
					border-right: 0;
				}
				&:first-of-type {
					border-left: 0;
				}
				&:hover {
					background: #ffffff54;
				}
			}
			button.active {
				background: $white;
				color: $green-delta;
			}
			& + .popup-content {
				padding: 1rem 2.2rem 2.2rem;
				h2 {
					margin-top: 0;
				}
			}
		}

		.popup-content {
			padding: 2.2rem;
			width: 100%;
			@include scrollbar-hidden;

			> *:first-child {
				margin-top: 0;
			}

			> *:last-child {
				margin-bottom: 0;
			}

			&.search {
				padding: 2.25rem 3.75rem;
			}

			&.login {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0 40px 40px;

				p {
					margin-top: 0;
					max-width: 370px;
					width: 100%;
				}

				.btn.btn-green {
					font-weight: 700;
				}

				.get-started-options {
					display: flex;
					flex-direction: column;
					gap: 1rem;
					margin: 0.5rem auto 0;
					max-width: 280px;
					width: 100%;

					.login-btn {
						display: flex;
						svg {
							height: auto;
							max-width: 100%;
						}
					}

					hr {
						background-color: $grey-gluon;
						border: none;
						margin: 0.25rem 0;
						opacity: 0.25;
						width: 100%;
						height: 1px;
					}
				}

				.email-signup {
					form {
						margin: 0 auto 1.5rem;
						max-width: 280px;
						width: 100%;
					}
					.link-row {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin: 0 auto;
						max-width: 280px;
						width: 100%;
					}
					hr {
						background-color: $grey-gluon;
						border: none;
						margin: 1.25rem auto;
						max-width: 280px;
						opacity: 0.25;
						width: 100%;
						height: 1px;
					}
				}
			}

			&.search {
				> h2 {
					color: $orange-fiery;
					font-family: $fontAlbra;
					font-size: 2rem;
					font-weight: 700;
					margin: 1.5rem 0;
				}

				> p {
					margin: 0 auto 1rem;
					max-width: 37.5rem;
				}
			}

			.recommended {
				margin-top: 3rem;

				h3 {
					margin: 0 auto 1.75rem;
					text-align: center;
				}

				.cards-list {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-column-gap: 1rem;
					grid-row-gap: 1rem;
				}
			}
		}

		.popup-footer {
			background-color: $orange-fiery;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px 70px;
			position: relative;
			width: 100%;

			.popup-close {
				cursor: pointer;
				position: absolute;
				top: 15px;
				right: 15px;

				svg {
					fill: #fff;
				}

				&:hover {
					opacity: 0.7;
				}
			}

			&.no-bg {
				background-color: transparent;

				.popup-close {
					svg {
						fill: #000;
					}
				}
			}

			.btn {
				padding: 0.5rem 0.75rem;
			}

			.clearAll {
				color: #fff;
				font-weight: 400;
				margin: 0 0 0 1rem;
				padding: 0.5rem;
				text-decoration: underline;
			}
		}
	}
}

/************
Categories/Legends Grid
Shown on pop-ups
***************/
.categories-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 25px;
	grid-row-gap: 25px;
	margin-bottom: 2.2rem;
	text-align: left;

	> * {
		display: flex;
		align-items: center;
		gap: 15px;
		font-size: 1.125em;
		font-weight: 400;
	}

	svg {
		background: #fff;
		border: 1px solid #000;
		border-radius: 100%;
		flex: 0 0 auto;
		transition: all ease-in-out 0.3s;

		&.active {
			background: $orange-fiery;
			border-color: $orange-fiery;
			fill: #fff;
		}
	}

	a {
		transition: all ease-in-out 0.15s;

		&:hover {
			color: $orange-fiery;
			text-decoration: none;

			svg {
				border-color: $orange-fiery;
				fill: $orange-fiery;
			}
		}

		&.selected {
			color: $orange-fiery;
			text-decoration: none;

			svg {
				background: $orange-fiery;
				border-color: $orange-fiery;
				fill: #fff;
			}
		}

		&.select-all {
			svg {
				padding: 0.5rem;
				width: 37px;
				height: 36px;
			}
		}
	}

	& + .btn {
		width: 100%;
	}
}

@media screen and (max-width: $screen-md) {
	.recommended {
		.cards-list {
			grid-template-columns: 1fr 1fr !important;
		}
	}
}

@media screen and (max-width: $screen-sm) {
	.recommended {
		.cards-list {
			grid-template-columns: auto !important;
		}
	}
}

@media screen and (max-width: $screen-xs) {
	.categories-grid {
		grid-template-columns: 1fr;
	}
}

/************
Search Recommendations Grid
Shown on pop-ups
***************/
.search-recommendations {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1.5rem;
	grid-row-gap: 1.5rem;
}

@media screen and (max-width: $screen-md) {
	.search-recommendations {
		grid-template-columns: 1fr;
	}
}

/************
Activities/Byways Grid
Shown on pop-ups
***************/

.byways-grid {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.userguide-grid {
	.byway-item {
		p {
			margin: 0px;
			padding: 0px;
			font-size: 0.9rem;
		}
		&.active {
			p {
				color: $orange-fiery;
			}
		}
	}
	.slick-next:before,
	.slick-prev:before {
		color: $orange-fiery;
	}
	.guide-boxes {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0;
	}

	.guide-box {
		&.box-1 {
			flex-basis: 60%;
			padding: 1rem 2rem;
			p {
				text-align: left;
			}
		}

		&.box-2 {
			flex-basis: 30%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h3 {
				margin: 0;
			}
		}
	}

	.line-color {
		border-width: 2px;
		border-style: solid;
		height: 0;
		width: 200px;
		margin: auto;
		margin-top: 1rem;
		border-color: #e69800;
	}
}

.activities-grid {
	display: grid;
	overflow: hidden;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 1fr;
	grid-column-gap: 5px;
	grid-row-gap: 5px;
	margin-top: 1rem;
	margin-bottom: 2rem;
	//activities popup
	.activity-icon {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		padding: 0 1rem;
		margin: 0 auto;
		svg {
			width: 45px;
			height: 45px;
			background: #fff;
			border: 1px solid #000;
			border-radius: 100%;
			flex: 0 0 auto;
			transition: all ease-in-out 0.3s;
		}
		&.active {
			svg {
				border-color: $orange-fiery;
				background: $orange-fiery;
				path {
					fill: $white;
				}
			}
		}
		h2 {
			text-align: left;
			font-size: 1rem;
			margin: 0 0 0.125rem;
			padding-left: 1rem;
		}
		p {
			text-align: left;
			font-size: 0.8rem;
			font-weight: 400;
			margin: 0;
			padding-left: 1rem;
		}
	}
}

.tab-content {
	//region card
	.region-label {
		margin: 0;
		margin-top: 1rem;
		padding: 0;
	}

	.region-description-image-container {
		display: flex;
		align-items: center;
		gap: 1rem;
	}

	.region-image {
		width: 50%;
		padding: 1rem;
		border-radius: 25px;
		height: 250px;
	}

	.region-description {
		width: 50%;
		height: auto;
		text-align: left;
	}
}

@media screen and (max-width: $screen-md) {
	.tab-content {
		.region-description-image-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 1rem;
		}

		.region-image {
			width: 100%;
			padding: 1rem;
		}

		.region-description {
			margin-top: 0;
			padding: 0 1rem;
			width: 100%;
		}
	}

	.popup {
		padding: 1rem;
	}
	.activities-grid {
		grid-template-columns: 1fr;
		grid-auto-rows: auto;
		grid-row-gap: 15px;
		margin-top: 1.5rem;

		.activity-icon {
			gap: 0.75rem;
			padding: 0;
			h2 {
				padding-left: 0;
			}
			p {
				padding-left: 0;
			}
		}
	}

	.guide-boxes {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;

		gap: 0;
	}

	.guide-box {
		&.box-1 {
			flex-basis: 60%;
			p {
				text-align: left;
			}
		}

		&.box-2 {
			flex-basis: 30%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h3 {
				margin: 0;
			}
		}
	}

	.line-color {
		border-width: 2px;
		border-style: solid;
		height: 0;
		width: 200px;
		margin: auto;
		margin-top: 1rem;
		border-color: #e69800;
	}
}

.marker-cluster {
	pointer-events: none;
	position: relative;
	width: 33px;
	height: 33px;
	opacity: 1;
	outline: none;
	border-radius: 5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease-in-out;
	background: #2c4a4a;
	border: 15px solid #2c4a4a;
	color: white;
	line-height: 37px;
	text-align: center;
	margin-top: 0.5rem;
	margin-bottom: 0;
}

//tabs

.tabs {
	background-color: $green-sky-splash;
	border-radius: 0.25rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 0.5rem;
	padding: 0.75rem;
}

.tab {
	margin: 0.25rem;
	display: block;
	text-align: center;
	text-decoration: none;
	background: #fff;
	color: #2c4a4a;
	border: none;
	border-radius: 16px;
	line-height: 1em;
	padding: 0.5rem 1rem;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.tab:hover {
	background: #2c4a4a;
	color: #fff;
}

.tab.active {
	background: #2c4a4a;
	color: #fff;
}

.tab-content {
	display: flex;
	flex-direction: column;
}

.tab-pane {
	display: none;

	&.active {
		display: block;
	}
}