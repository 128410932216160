/*********************
Styles for displaying a saved itinerary
*********************/
#travel-assistant {
	&.display-itinerary {
		.main-content {
			.filterBar {
				align-items: center;
				h1 {
					color: #fff;
					font-size: 1.5rem;
					line-height: 1em;
					margin: 0;
					text-align: center;
				}
			}
			.map-wrapper {
				.vita-map {
					.ac-card-wrapper .card-image .add-to-cart {
						display: none;
					}
				}
			}
		}
		.side-panel {
			.side-panel-content {
				.direction-item {
					background-color: #fff;
					box-shadow: $boxShadow;
					margin: 0;
					padding: 1rem;
				}
			}
		}
	}
}

@media screen and (min-width: ($screen-md + 1)) {
	#travel-assistant {
		&.display-itinerary {
			.side-panel {
				margin-left: auto;
				margin-right: -#{$sidebarWidth};
				&.show {
					margin-left: auto;
					margin-right: 0;
				}
			}
		}
	}
}

@media screen and (max-width: $screen-md) {
	#travel-assistant {
		&.display-itinerary {
			.main-content {
				.filterBar {
					.navLinks {
						.itinerary-directions {
							display: none;
						}
					}
				}
			}
		}
	}
}
