.itinerary-tabs {
	background-color: #fff;
	padding: 2rem 0 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
	.btn{
		margin:0 .5rem;
		&.active{
			background: #2f6062;
			border-color: #2f6062;
			color: #fff;
		}
	}
}
.dashboard-wrapper {
	background-color: #fff;
	height: 100%;
	padding: 4rem 2rem 0;
	.dashboard-container {
		display: grid;
		grid-template-columns: 12rem 1fr 12rem;
		grid-column-gap: 2rem;
		height: 100%;
		margin: 0 auto;
		max-width: 92rem;
		position: relative;
		.return-to-map {
			justify-self: flex-end;
			padding: 0 1rem;
		}
	}
}

@media screen and (min-width: ($screen-md + 1)) and (max-width: 1400px) {
	.dashboard-wrapper {
		.dashboard-container {
			.return-to-map {
				justify-self: flex-start;
			}
		}
	}
}

@media screen and (max-width: $screen-md) {
	.dashboard-wrapper {
		padding: 4.5rem 0 0;
		.dashboard-container {
			grid-template-columns: 1fr;
			.return-to-map {
				display: none;
			}
		}
	}
}

@import "./account";
@import "./save-itinerary";
