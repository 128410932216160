.cart-wrapper {
	position: relative;
	.cart-count {
		background-color: $orange-adobe;
		border-radius: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.6rem;
		line-height: 1em;
		padding: 0.25rem;
		position: absolute;
		bottom: -0.5rem;
		right: -0.5rem;
		min-width: 1rem;
		height: 1rem;
	}

	.cart-dropdown {
		background-color: #fff;
		border-radius: 10px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin-top:-2px;
		box-shadow: 0 0 6px rgb(0 0 0 / 16%);
		max-height: fit-content;
		position: absolute;
		top: 50px;
		right: 0;
		width: 350px;
		z-index: 2;
		padding:0 15px 0 15px;
		.cart-items {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			list-style: none;
			padding: 15px 0 25px 0;
			margin: 0;
			max-height: 400px;
			overflow-y: auto;
			@include scrollbar;
			li {
				border: 1px solid #d5d5d5;
				border-radius: 0.25rem;
				box-shadow: 0 0 6px rgb(0 0 0 / 16%);
				display: flex;
				align-items: center;
				gap: 0.75rem;
				font-size: 1rem;
				font-weight: 400;
				padding: 1rem 0.75rem;
				.cart-item-remove {
					background-color: #fff;
					border: 1px solid #000;
					border-radius: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex: 0 0 auto;
					@include standard-transition;
					width: 20px;
					height: 20px;
					&:hover {
						background-color: $orange-fiery;
						border-color: $orange-fiery;
						svg {
							fill: #fff;
						}
					}
					svg {
						fill: #000;
						width: 10px;
						@include standard-transition;
					}
				}
			}
		}
		hr {
			background-color: #d5d5d5;
			border: none;
			height: 1px;
			margin: 0 -15px;
		}
		.cart-actions {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 25px 15px 15px;
			.btn-clear {
				color: #9f9f9f;
				font-size: 0.9rem;
				font-weight: 400;
				text-decoration: underline;
				&:hover {
					color: $orange-fiery;
				}
			}
			.btn-review {
				border-radius: 1rem;
				font-family: $fontEuropa;
				font-size: 0.9rem;
				line-height: 1em;
				padding: 0.5rem 1.25rem;
			}
		}
		.cart-tab-headers{
			display:flex;
			justify-content: center;
			align-items: center;
			.btn{
				margin:1rem .5rem;
				&.active, &:hover, &:active{
					background: $green;
					border-color: $green;
					color:$white;
					box-shadow: $boxShadow;
				}
			}
		}
	}
}

@media screen and (max-height: 700px) {
	.cart-wrapper {
		.cart-dropdown {
			.cart-items {
				max-height: calc(60vh - #{$filterHeight});
			}
		}
	}
}

@media screen and (max-width: $screen-md) {
	.cart-wrapper {
		.cart-dropdown {
			left: 0;
			right: auto;
			width: 350px;
		}
	}
}
