@mixin standard-transition {
	transition: all 0.2s ease-in-out;
}

@mixin scrollbar {
	overflow-y: auto;
	scrollbar-color: #707070 #fff; //firefox
	scrollbar-width: thin; //firefox
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background-color: #fff;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #707070;
		border-radius: 6px;
	}
}

@mixin scrollbar-hidden {
	overflow-y: auto;
	-ms-overflow-style: none;
	scrollbar-width: none; //firefox
	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin sr-only {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}
