/*********************
Map Filter Bar
*********************/
$logoWidth: 5rem;

//BASE STYLES
.filterBar {
	background-color: $green-pacific;
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	height: $filterHeight;
	padding: 0 1.5rem;
	position: relative;
	width: 100%;
	z-index: 2;

	.filterSearch {
		position: absolute;
		top: calc(#{$filterHeight} + 3rem);
		right: 2rem;
		max-width: 250px;
		width: 100%;
		z-index: 1;

		.search-box {
			background-color: #fff;
			border-radius: 5rem;
			box-shadow: 0 0 6px #00000029;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 0.75rem;
			padding: 0.5rem 1rem;

			button,
			input {
				background: none;
				border: none;
				color: #7e7e7e;
				font-family: $fontEuropa;
				font-size: 1rem;
				line-height: 1em;
				padding: 0;
				outline: none;
			}

			input {
				flex: 1 0 auto;
			}

			button {
				cursor: pointer;
				flex: 0 1 auto;

				svg {
					stroke: #1f1f45;
				}
			}
		}
	}

	.vi-logo {
		display: flex;
		align-items: center;
		width: $logoWidth;
		height: auto;
	}

	.filterOptions {
		display: none;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		grid-column: 2;

		.selectActivtyByway{
			display: block;
			text-align: center;
			text-decoration: none;
			background:$green-delta;
			border: none;
			border-radius: 16px;
			gap: 0.5rem;
			height: 32px;
			line-height: 1em; 
			padding: 0.4rem 1rem;
			transition: all ease-in-out 0.3s;
			width: auto;
			
			cursor: pointer;
			color: $white;
			font-size: 1.15rem; 
			line-height: 1em;
			margin: 0;
			font-weight:400;
			padding: 0;
		}

		&.active {
			display: flex;
		}

		.clearAll {
			color: #fff;
			font-size: 0.9rem;
			font-weight: 400;
			line-height: 1em;
			text-decoration: underline;
			@include standard-transition;
			&:hover {
				text-decoration: none;
			}
		}

		.dd-wrapper {
			width: auto;

			.dd-header {
				cursor: pointer;
				font-size: 1rem;
				font-weight:300;
				text-align: left;
				background-color: $green-delta;
				font-family: $fontEuropa;
				color:$white;
				  border: none;
				  border-radius: 16px;
				  gap: 0.5rem;
				  height: 32px;
				  line-height: 1em;
				  padding: 0.4rem 1rem;
				  transition: all ease-in-out 0.3s;
				  width: auto;
				  min-width: 130px;
			}

			.dd-list {
				border-radius: 10px;
				box-shadow: $boxShadow;
				top: calc($filterBtnHeight + (($filterHeight - $filterBtnHeight) / 2));
				max-height: fit-content;
				padding: 1rem;
				width: 300px;

				.dd-list-search-bar {
					border: 1px solid #d5d5d5;
					border-radius: 0.5rem;
					font-size: 1rem;
					margin-bottom: 1rem;
					outline: none;

					&:focus {
						border-color: $orange-fiery;
					}
				}

				.dd-scroll-list {
					max-height: 500px;
					overflow-y: auto;
					padding: 0.5rem 1rem 0.5rem 0;
					@include scrollbar;

					.dd-list-item {
						border-radius: 2rem;
						display: flex;
						flex-direction: row-reverse;
						justify-content: flex-end;
						gap: 0.625rem;
						font-size: 1.25rem;
						font-weight: 400;
						line-height: 1em;
						padding: 0.625rem 1.25rem;
						white-space: normal;

						&:hover {
							background-color: $orange-fiery;

							svg path {
								fill: #fff;
							}
						}

						svg path {
							fill: $orange-fiery;
						}
					}
				}
			}
		}
	}

	.navLinks {
		display: flex;
		align-items: center;
		gap: 2.5rem;
		grid-column: 3;
		justify-self: end;

		.search-toggle {
			display: none;
		}

		.nav-link {
			color: #fff;
			display: flex;
			align-items: center;
			gap: 0.65rem;
			font-size: 1.25rem;
			position: relative;

			svg {
				fill: #fff;
				height: 1.75rem;
				width: auto;
			}

			&:hover {
				text-decoration: none;
			}
		}
	}
}

//LANDSCAPE MODE/HEIGHT
@media screen and (max-height: 700px) {
	.filterBar {
		.filterOptions {
			.dd-wrapper {
				.dd-list {
					.dd-scroll-list {
						max-height: calc(50vh - #{$filterHeight});
					}
				}
			}
		}
	}
}

//MEDIUM-DESKTOP
@media screen and (min-width: ($screen-md + 1)) and (max-width: 1500px) {
	.filterBar {
		grid-template-columns: $logoWidth 1fr auto;
		grid-column-gap: 1.5rem;

		.filterOptions {
			position: relative;
		}

		.navLinks {
			gap: 1.75rem;

			.nav-link {
				font-size: 1rem;
			}
		}
	}
}

@media screen and (min-width: ($screen-md + 1)) {
	#travel-assistant .filterBar .return-to-map {
		display: none;
	}
}

//OPEN SIDEBAR
@media screen and (min-width: 1351px) {
	#travel-assistant .side-panel.show + .main-content .filterBar {
		grid-template-columns: $logoWidth 1fr auto;
		grid-column-gap: 1.5rem;

		.filterOptions {
			position: relative;
		}
	}
}

@media screen and (min-width: ($screen-md + 1)) and (max-width: 1350px) {
	#travel-assistant .side-panel.show + .main-content {
		.filterBar {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 1rem;
			height: $filterHeightListView;
			padding: 1rem 1rem 1rem calc(#{$logoWidth} + 3rem);

			.filterSearch {
				position: unset;
				transform: none;
			}

			.vi-logo {
				position: absolute;
				left: 1.5rem;
			}

			.filterOptions {
				gap: 10px;
				position: relative;

				.dd-wrapper {
					position: unset;
					
					.dd-list {
						top: 48px;
						left: 50%;
						transform: translate(-50%, 0);
						max-width: 30rem;
						width: 90%;
					}
				}
			}

			.navLinks {
				gap: 1rem;
				justify-content: center;
				position: absolute;
				top: calc($filterHeightListView + 1.5rem);
				left: calc(50% + ((#{$logoWidth} + 3rem) / 2));
				white-space: nowrap;
				transform: translate(-50%, 0);
				width: 100%;
				z-index: 1;

				.nav-link {
					background-color: $green-bound;
					border-radius: 50px;
					font-size: 0.9rem;
					font-weight: 400;
					gap: 0.75rem;
					padding: 0.4rem 1rem;

					span {
						order: 2;
					}

					svg {
						height: 14px;
						order: 1;
					}

					.cart-count {
						position: unset;
						order: 3;
					}
				}
			}
		}

		.map-wrapper {
			height: $mapHeightListView;
		}
	}
}

//TABLET + PHONE
@media screen and (max-width: $screen-md) {



	.dd-header {
		font-size: .8rem !important;
	}

	#travel-assistant .side-panel.show + .main-content .filterBar .filterOptions {
		display: none;
	}

	.filterBar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		height: $filterHeight;

		.vi-logo {
			position: unset;
		}

		.filterSearch {
			display: none;

			&.show {
				display: block;
				max-width: 90%;
				top: calc($filterHeight + 1rem);
				left: 50%;
				transform: translate(-50%, 0);
				z-index: 2;
			}

			.search-box {
				padding: 0.75rem 1rem;
			}
		}

		.main-content.view-dashboard & .filterOptions {
			display: none;
		}

		.filterOptions {
			gap: 0.5rem;
			justify-content: center;
			position: absolute;
			top: calc($filterHeight + 1rem);
			left: 50%;
			transform: translate(-50%, 0);
			z-index: 1;


			.dd-wrapper {
				position: unset;
				white-space: nowrap;

				.dd-list {
					top: 48px;
					left: 50%;
					transform: translate(-50%, 0);
					max-width: 30rem;
					width: 90%;

					.dd-list-search-bar {
						display: none;
					}
				}
			}

			.clearAll {
				background-color: $green-pacific;
				padding: 0.4rem 0.75rem;
				border-radius: 1rem;
				font-size: 0.75rem;
				text-decoration: none;
				transition: all ease-in-out 0.3s;
				position: absolute;
				top: 3rem;
				&:hover {
					background-color: $orange-fiery;
				}
			}
		}

		.navLinks {
			gap: 1.5rem;
			justify-content: flex-end;
			padding: 0;
			position: unset;
			transform: none;

			.search-toggle {
				display: block;

				svg {
					stroke: #fff;
					width: 1.5rem;
					height: 1.5rem;
				}

				&:hover {
					cursor: pointer;
				}
			}

			.cart-wrapper {
				.cart-dropdown {
					left: auto;
					right: -3rem;
				}
			}

			.nav-link {
				background: none;
				padding: 0;

				span {
					display: none;

					&.cart-count {
						display: block;
						position: absolute;
					}
				}

				svg {
					width: 1.5rem;
					height: 1.5rem;
				}
			}
		}

		.return-to-map {
			position: absolute;
			top: calc($filterHeight + 1rem);
			left: 0;
			text-align: center;
			width: 100%;
			z-index: 1;

			.mapview-btn {
				border-radius: 2rem;
				gap: 0.75rem;
				font-size: 1rem;
				line-height: 1em;
				min-height: 37px;
				padding: 0.5rem 1.5rem;

				svg {
					width: 0.5rem;
				}
			}
		}
	}

}
