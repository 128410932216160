.field-group {
	margin: 0 0 1rem;
	.validation {
		color: $red;
		display: none;
		font-size: 0.8rem;
		line-height: 1em;
		margin: 0.5rem 0 0;
		text-align: left;
	}
	label {
		display: block;
		font-weight: 700;
		margin: 0 0 0.25rem;
		text-align: left;
	}
	.input-block {
		border: 1px solid #ddd;
		font-size: 0.9rem;
		line-height: 1em;
		margin: 0;
		outline: none;
		padding: 0.75rem;
		width: 100%;
		&.error {
			border-color: $red;
			+ .validation {
				display: block;
			}
		}
	}
}

.sr-only {
	@include sr-only;
}
