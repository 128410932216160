#travel-assistant {
	&.error404 {
		background-color: $blue-soft;
		.main-content {
			padding: 2rem 1rem;
			@include scrollbar-hidden;
			.content-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: 1rem;
				min-height: 100%;
				position: relative;
				text-align: center;
				z-index: 1;
				h1 {
					margin: 0;
				}
				h2 {
					margin: 0 auto 1rem;
				}
			}
		}
	}
}
