/*********************
Main layout
*********************/
html,
body {
	height: 100%;
	min-height: -webkit-fill-available !important;
}

#travel-assistant {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	&.tutorial-active * {
		transition: none !important;
	}
	.main-content {
		flex: 1;
		height: 100%;
		.map-wrapper {
			height: $mapHeight;
			position: relative;
			z-index: 1;
			.vita-map {
				height: 100%;
				width: 100%;
			}
			.map-buttons {
				.list-view {
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					position: absolute;
					top: 3rem;
					left: 3rem;
					z-index: 998;
				}
				.helper-btns {
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 1rem;
					text-align: center;
					position: absolute;
					bottom: 2rem;
					left: 3rem;
					z-index: 998;
					a {
						background: #fff;
						box-shadow: $boxShadow;
						border-radius: 4rem;
						display: flex;
						align-items: center;
						gap: 0.5rem;
						padding: 0.75rem;
						width: 4rem;
						height: 4rem;
						@include standard-transition;
						svg {
							height: 100%;
							width: 100%;
							transition: fill ease 0.2s;
						}
						span {
							display: none;
						}
						&.expanded {
							border-radius: 0;
							padding: 1rem;
							width: 100%;
							height: auto;
							svg {
								height: 1rem;
								width: auto;
							}
							span {
								display: flex;
								align-items: center;
								justify-content: space-between;
								flex: 1;
							}
						}
						&:hover {
							background: $green-calyso;
							border-color: $green-calyso;
							color: #fff;
							text-decoration: none;
							svg {
								fill: #fff;
							}
						}
					}
					.map-legend-wrapper {
						.map-legend {
							display: flex;
							flex-direction: column;
							gap: 0.5rem;
							opacity: 0;
							pointer-events: none;
							width: 0;
							height: 0;
							.activities,
							.regions {
								.accordion__button {
									background-color: #eee;
									cursor: pointer;
									display: flex;
									align-items: center;
									gap: 1rem;
									font-size: 1.15rem;
									font-weight: 700;
									margin: 0;
									padding: 0.5rem 1rem;
									&:before {
										border-bottom: 2px solid;
										border-right: 2px solid;
										content: "";
										display: block;
										flex: 0 0 auto;
										transform: rotate(-45deg);
										width: 0.5rem;
										height: 0.5rem;
									}
									&[aria-expanded="true"]:before {
										transform: rotate(45deg);
									}
								}
								.accordion__panel:not([hidden]) {
									display: flex;
									flex-direction: column;
									gap: 0.75rem;

									padding: 0.75rem 0.75rem 0.75rem 0.25rem;
									overflow-y: auto;
									width: 15rem;

									@include scrollbar;

									@media screen and (max-height: 65rem) {
										max-height: 300px;
									}

									@media screen and (max-height: 55rem) {
										max-height: 200px;
									}

									@media screen and (max-height: 45rem) {
										max-height: 140px;
									}

									.title {
										display: flex;
										align-items: center;
										gap: 0.2rem;
										font-size: 1rem;
										font-weight: 400;
										> svg {
											flex: 0 0 auto;
											height: 2.25rem;
											width: 2.25rem;
										}
										span {
											flex: 1;
											text-align: left;
										}
										button {
											background: #fff;
											border: 1px solid $green-calyso;
											border-radius: 1rem;
											cursor: pointer;
											display: flex;
											align-items: center;
											justify-content: center;
											width: 1.5rem;
											height: 1.5rem;
											@include standard-transition;
											svg {
												width: 0.75rem;
												height: 0.75rem;
											}
											&:hover {
												background-color: $green-calyso;
												svg {
													fill: #fff;
												}
											}
										}
									}
									.details {
										font-size: 0.8rem;
										margin: 0.25rem 0 0;
										text-align: left;
									}
								}
							}
							.regions {
								.accordion__panel:not([hidden]) {
									.title {
										gap: 0.75rem;
										svg {
											fill: none;
											stroke: #000;
											stroke-width: 8px;
											height: 2.25rem;
											width: 2.25rem;
										}
									}
								}
							}
						}
						&.open {
							background: #fff;
							box-shadow: $boxShadow;
							a {
								background: $green-calyso;
								border-color: $green-calyso;
								color: #fff;
								text-decoration: none;
								svg {
									fill: #fff;
								}
							}
							.map-legend {
								opacity: 1;
								padding: 1rem;
								pointer-events: all;
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
	.side-panel {
		flex: 0 0 auto;
		height: 100%;
	}
}

@media screen and (min-width: ($screen-md + 1)) and (max-width: 1350px) {
	#travel-assistant {
		.main-content {
			.map-wrapper {
				.map-buttons {
					.list-view {
						&.open {
							top: 1.25rem;
							left: 1.25rem;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: $screen-md) {
	#travel-assistant {
		.main-content {
			&.view-dashboard {
				position: relative;
				z-index: 9999;
			}
			&.view-map {
				.map-wrapper {
					height: $mapHeightMobile;
				}
			}
			.map-wrapper {
				.map-buttons {
					.list-view {
						display: none;
					}
					.helper-btns {
						bottom: 1rem;
						left: 1rem;
						a {
							width: 3rem;
							height: 3rem;
							&.expanded {
								width: 100%;
								height: auto;
							}
						}
					}
				}
			}
		}
		.side-panel {
			height: 90%;
		}
	}
}
