/*********
SIDE PANEL
*********/
.side-panel {
	box-shadow: $boxShadow;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;
	transition: all ease-in-out 0.3s;
	width: 100%;

	.side-panel-header {
		background-color: $green-delta;
		padding: 35px 20px;

		.sidebar-title {
			color: #fff;
			font-size: 1.5rem;
			font-weight: 700;
			line-height: 1.2em;
			margin: 0;
			text-align: center;
		}
	}

	.side-panel-content {
		background-color: $green-sky-splash;
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 15px;
		@include scrollbar-hidden;
	}
}

@media screen and (min-width: ($screen-md + 1)) {
	.side-panel {
		margin-left: -#{$sidebarWidth};
		max-width: $sidebarWidth;

		&.show {
			margin-left: 0;
		}

		.side-panel-mobile {
			display: none;
		}
	}
}

@media screen and (max-width: $screen-md) {
	.side-panel {
		border-radius: 10px 10px 0 0;
		max-width: 100%;
		position: fixed;
		left: 0;
		bottom: calc(-90% + #{$sidebarMobile});

		&:before {
			background-color: rgb(0 0 0 / 0%);
			content: " ";
			position: absolute;
			top: -100%;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: all ease-in-out 0.3s;
			visibility: hidden;
			z-index: -1;
		}

		&.show {
			bottom: 0;
			z-index: 999;
			&:before {
				background-color: rgb(0 0 0 / 50%);
				top: -20%;
				opacity: 1;
				visibility: visible;
				z-index: 1;
			}
		}

		.side-panel-mobile {
			background-color: #fff;
			border-radius: 10px 10px 0 0;
			color: $grey-gluon;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 0.75rem;
			height: $sidebarMobile;
			font-size: 1.125rem;
			font-weight: 400;
			line-height: 1em;
			padding: 1.5rem 1rem;

			.toggle-bar {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;

				span {
					background-color: #707070;
					border-radius: 10px;
					height: 4px;
					width: 50px;
				}
			}

			&:hover {
				text-decoration: none;
			}
		}

		.side-panel-header {
			padding: 20px 15px;
		}

		.side-panel-mobile,
		.side-panel-header,
		.side-panel-content {
			position: relative;
			z-index: 2;
		}
	}
}
