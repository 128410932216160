.dashboard-wrapper .dashboard-container.save-itinerary {
	.itinerary-content {
		height: 100%;
		padding: 0 1rem 3rem;
		@include scrollbar;

		h3 {
			font-size: 1.25rem;
			margin-top: 0;
			margin-bottom: 1rem;
		}

		.my-itinerary {
			display: flex;
			gap: 1.5rem;

			.itinerary-cart {
				flex: 1;

				.cart-wrapper {
					background-color: #fff;
					border-radius: 0.25rem;
					box-shadow: 0 0 6px #00000029;

					.starting-point,
					.finishing-point {
						border: 1px solid #d5d5d5;
						border-radius: 0.25rem;
						display: flex;
						align-items: center;
						gap: 0.75rem;
						margin: 0.25rem 0 0;
						padding: 0 0.75rem;

						svg {
							fill: #7e7e7e;
						}

						input {
							border: none;
							color: #7e7e7e;
							font-family: $fontEuropa;
							font-size: 1rem;
							outline: none;
							padding: 0.75rem 0;
							width: 100%;
						}
					}

					hr {
						background-color: #d5d5d5;
						border: none;
						height: 1px;
						margin: 1rem -1rem;
					}

					.cart-header {
						display: flex;
						flex-direction: column;
						gap: 1rem;
						padding: 1rem;
						p {
							font-size: 1rem;
							margin: 0;
							strong {
								display: block;
								font-size: 0.85rem;
							}
						}
						.location {
							.location-title {
								display: flex;
								align-items: center;
								justify-content: space-between;
							}
							.location-name {
								display: flex;
								align-items: center;
								justify-content: space-between;
								a {
									padding: 0 0.5rem;
								}
							}
						}
						.custom-endpoint {
							background-color: #eee;
							border: none;
							border-radius: 0.25rem;
							cursor: pointer;
							display: flex;
							align-items: center;
							gap: 0.25rem;
							font-size: 0.9rem;
							padding: 0.15rem 0.25rem;
						}
					}

					div[role="presentation"] {
						background: $blue-soft;
						padding: 1rem 0 1rem 1rem;
						> p {
							font-size: 0.9rem;
							margin: 0 0 1rem;
							opacity: 0.8;
						}
					}

					.edit-cart {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 1rem;
						p {
							font-weight: 700;
							margin: 0;
						}
						a {
							background-color: $green-pacific;
							border-radius: 1rem;
							color: #fff;
							display: flex;
							align-items: center;
							gap: 0.25rem;
							font-size: 0.9rem;
							padding: 0.25rem 0.5rem;
							margin-right: 0.5rem;
							@include standard-transition;
							svg {
								fill: #fff;
								height: 0.85rem;
								@include standard-transition;
							}
							&:hover {
								background-color: $green-calyso;
								text-decoration: none;
							}
						}
					}

					.cart-items {
						display: flex;
						flex-direction: column;
						list-style: none;
						margin: 0;
						max-height: 350px;
						overflow-y: auto;
						padding: 0 1rem 1rem 0;
						@include scrollbar;

						li {
							padding: 0.5rem 0;
							.cart-item {
								background-color: #fff;
								border: 1px solid #d5d5d5;
								border-radius: 0.5rem;
								box-shadow: 0 0 6px rgb(0 0 0 / 16%);
								cursor: grab;
								display: flex;
								align-items: center;
								gap: 0.6rem;
								font-size: 1rem;
								font-weight: 400;
								line-height: 1.1em;
								padding: 0.5rem 1rem 0.5rem 0.5rem;

								svg {
									flex: 0 0 auto;
									width: 2rem;
								}

								.cart-item-remove {
									background-color: #fff;
									border: 1px solid #000;
									border-radius: 100%;
									display: flex;
									align-items: center;
									justify-content: center;
									flex: 0 0 auto;
									margin-left: 0.25rem;
									@include standard-transition;
									width: 20px;
									height: 20px;

									&:hover {
										background-color: $orange-fiery;
										border-color: $orange-fiery;

										svg {
											fill: #fff;
										}
									}

									svg {
										fill: #000;
										width: 10px;
										@include standard-transition;
									}
								}
							}
						}
					}

					.sort-waypoints {
						background-color: $green-pacific;
						border-radius: 0 0 0.25rem 0.25rem;
						color: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 0.5rem;
						line-height: 1.1em;
						padding: 1rem;
						@include standard-transition;

						svg {
							fill: #fff;
							width: 1rem;
						}

						span {
							text-decoration: none;
						}

						&.disabled {
							cursor: not-allowed;
							opacity: 0.5;
						}

						&:hover {
							text-decoration: none;
						}

						&:not(.disabled):hover {
							background-color: $green-calyso;
						}
					}
				}
			}

			.itinerary-map {
				flex: 2;
				display: flex;
				flex-direction: column;

				.details-content {
					z-index: 999;
					display: flex;
					justify-content: space-between;
					width: 100%;

					@media screen and (max-width: 980px) {
						flex-direction: column;
						gap: 0.5rem;
						padding: 1rem;
					}

					.distance {
						font-size: 1rem;
						margin: 0;
					}

					.time {
						font-size: 1rem;
						margin: 0;
					}
				}

				.itin-map {
					background: #eee;
					border-radius: 0.25rem;
					min-height: 500px;
					position: relative;
					width: 100%;
					height: 100%;
					@media screen and (max-width: 980px) {
						min-height: 50vh;
					}
				}
			}
		}

		.print-class {
			background: white;
			z-index: -1;
			width: 100%;
			height: 100%;
			padding: 1rem;
		}

		.recommended {
			margin-top: 3rem;

			h3 {
				margin: 0 auto 1.75rem;
				text-align: center;
			}

			.cards-list {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr;
				grid-column-gap: 1rem;
				grid-row-gap: 1rem;
			}

			&.tt {
				h3 {
					margin: 0 auto;
					text-align: center;
				}

				p {
					margin: 0.25rem auto 2rem auto;
					text-align: center;
				}
			}
		}
	}

	.itinerary-save {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding-top: 2.5rem;

		a {
			display: inline-flex;
			align-items: center;
			gap: 0.75rem;
			font-size: 1.25rem;

			svg {
				flex: 0 0 auto;
			}

			span {
				color: $orange-fiery;
			}

			&:hover {
				text-decoration: none;

				svg {
					fill: $orange-fiery;
				}
			}
		}
	}

	.tt-cart-items{
		padding:15px 0;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: 1rem;
		grid-row-gap: 1rem;
		&.is-empty{
			display:flex;
			justify-content: flex-start;
			align-items: center;
			grid-column-gap: 0;
			grid-row-gap: 0;
			margin-top:0;
			padding-top:0;
		}
	}
}

@media screen and (min-width: ($screen-md + 1)) and (max-width: 1400px) {
	.dashboard-wrapper .dashboard-container.save-itinerary {
		align-content: flex-start;
		/* autoprefixer grid: off */
		grid-template-columns: auto 1fr;
		grid-row-gap: 4rem;

		.itinerary-content {
			grid-column: 1/3;
		}

		.itinerary-save {
			flex-direction: row;
			gap: 2rem;
			grid-column: 2;
			grid-row: 1;
			justify-self: end;
			padding: 0 1rem;

			a svg {
				height: 18px;
				width: auto;
			}
		}
	}
}

@media screen and (min-width: ($screen-md + 1)) {
	.dashboard-wrapper .dashboard-container.save-itinerary {
		.mobile-only {
			display: none;
		}
	}
}

@media screen and (max-width: $screen-md) {
	.dashboard-wrapper .dashboard-container.save-itinerary {
		.itinerary-content {
			padding: 1rem 1rem 3rem;

			h3 {
				text-align: center;
			}

			.my-itinerary {
				flex-direction: column;
				gap: 2.5rem;

				.itinerary-map {
					margin: 0 -1rem;
				}

				.itinerary-cart {
					.cart-wrapper {
						.cart-items {
							max-height: 300px;
						}
					}
				}

				.itinerary-save {
					padding: 0 2rem;
				}
			}

			.recommended {
				margin-top: 4rem;

				.cards-list {
					grid-template-columns: auto auto;
					justify-content: center;
					margin: 0 auto;
					max-width: 35rem;

					/* autoprefixer grid: off */
				}
			}
		}

		.desktop-only {
			display: none;
		}
	}
}

@media screen and (max-width: $screen-sm) {
	.dashboard-wrapper .dashboard-container.save-itinerary {
		.itinerary-content {
			.recommended {
				.cards-list {
					grid-template-columns: auto;
					max-width: 18rem;
				}
			}
		}
	}
}
