/* image grid sizes & spacing*/
$iteneraryGap: 0.3rem;
$itinerarySmall: 11.25rem;
$itineraryFull: calc(#{$itinerarySmall} * 2 + #{$iteneraryGap});

.dashboard-wrapper .dashboard-container.my-account {
	grid-template-rows: min-content 1fr;
	grid-row-gap: 3rem;

	.account-links {
		display: flex;
		justify-content: center;
		gap: 2rem;

		@media screen and (max-width: 980px) {
			padding: 1rem;
		}
	}

	.account-content {
		grid-column: 1/4;
		grid-row: 2;
		padding: 1rem 1rem 3rem;
		@include scrollbar-hidden;

		h3 {
			font-size: 1.25rem;
			margin-top: 0;
			text-align: center;
		}

		.account-itineraries {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 2rem;
			grid-row-gap: 2rem;

			h4 {
				font-size: 1.15rem;
				margin: 0;
			}

			.create-new-itinerary {
				background-color: #fff;
				border: 1px solid #d5d5d5;
				border-radius: 0.75rem;
				box-shadow: 0 0 6px rgb(0 0 0 / 16%);
				display: flex;
				align-items: center;
				justify-content: center;
				min-height: 100px;

				h4 {
					color: #000;
					display: flex;
					align-items: center;
					gap: 0.5rem;

					.icon-plus {
						background-color: #000;
						border-radius: 1.5rem;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 1.25rem;
						height: 1.25rem;

						svg {
							fill: #fff;
							width: 0.75rem;
							height: 0.75rem;
						}
					}
				}

				&:hover {
					text-decoration: none;
				}
			}

			.itinerary {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: $iteneraryGap;
				grid-row-gap: $iteneraryGap;
				position: relative;

				&:before {
					content: "";
					background: linear-gradient(transparent, #000);
					border-radius: 0.5rem;
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 5rem;
				}

				h4 {
					color: #fff;
					position: absolute;
					left: 0.75rem;
					bottom: 0.75rem;
				}

				.itinerary-image {
					height: $itinerarySmall;

					img {
						border-radius: 0.5rem;
						object-fit: cover;
						width: 100%;
						height: 100%;
					}

					&:nth-child(2) {
						grid-column: 1;
						grid-row: 1/3;
						height: $itineraryFull;
					}

					&:nth-child(3),
					&:nth-child(4) {
						grid-column: 2;
					}
				}

				&.images-1 {
					.itinerary-image {
						grid-column: 1/3;
					}
				}

				&.images-2 {
					.itinerary-image {
						&:nth-child(3) {
							grid-row: 1/3;
							height: $itineraryFull;
						}
					}
				}
			}
		}

		.account-help {
			.accordion {
				box-shadow: $boxShadow;
				margin: 2rem auto 0;
				max-width: 40rem;

				.accordion__item {
					background: #fff;
					border: 1px solid #d5d5d5;

					& + .accordion__item {
						margin-top: -1px;
					}

					.accordion__button {
						cursor: pointer;
						display: flex;
						align-items: center;
						gap: 1rem;
						font-weight: 700;
						padding: 1.5rem;

						&:before {
							border-bottom: 2px solid;
							border-right: 2px solid;
							content: "";
							display: block;
							flex: 0 0 auto;
							transform: rotate(-45deg);
							width: 0.5rem;
							height: 0.5rem;
						}

						&[aria-expanded="true"]:before {
							transform: rotate(45deg);
						}
					}

					.accordion__panel {
						padding: 0 1.5rem 1.5rem;

						*:first-child {
							margin-top: 0;
						}

						*:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			.more-info {
				margin: 2rem auto 0;
				max-width: 40rem;
				text-align: center;

				p {
					padding: 0 1rem;
				}
			}
		}
	}
}

$itinerarySmall: 6.5rem;
$itineraryFull: calc(#{$itinerarySmall} * 2 + #{$iteneraryGap});

@media screen and (max-width: $screen-lg) {
	.dashboard-wrapper .dashboard-container.my-account {
		.account-content {
			.account-itineraries {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}

@media screen and (max-width: $screen-md) {
	.dashboard-wrapper .dashboard-container.my-account {
		grid-row-gap: 0;

		.account-content {
			grid-column: 1;
		}
	}
}

@media screen and (max-width: $screen-sm) {
	.dashboard-wrapper .dashboard-container.my-account {
		.account-content {
			.account-itineraries {
				grid-template-columns: 1fr;

				.create-new-itinerary {
					min-height: $itineraryFull;
				}

				.itinerary {
					.itinerary-image {
						height: $itinerarySmall;

						&:nth-child(2) {
							height: $itineraryFull;
						}
					}

					&.images-two {
						.itinerary-image {
							&:nth-child(3) {
								height: $itineraryFull;
							}
						}
					}
				}
			}
		}
	}
}
