/*********************
Map specific styles
*********************/
.leaflet-container {
	//leaflet map markers
	.leaflet-marker-icon {
		border: none;
		border-radius: 5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		@include standard-transition;

		svg {
			border: none;
			border-radius: 5rem;
			box-shadow: $boxShadow;
			width: 40px;
			height: 40px;
			@include standard-transition;

			&.active {
				background-color: $green-pacific;
				fill: #fff;
				border-radius: 5rem;
			}
		}

		&.city-marker {
			background-color: #fff;
			svg {
				box-shadow: none;
				fill: #000;
				width: 80px;
				height: 80px;
			}

			&:focus-within,
			&:focus,
			&:hover,
			&.active {
				background-color: #fff;
				svg {
					fill: #000;
				}
			}
		}

		&:focus-within,
		&:focus,
		&:hover,
		&.active {
			background-color: $green-pacific;

			svg {
				fill: #fff;
			}
		}
	}

	//leaflet map marker popups
	.leaflet-popup {
		.leaflet-popup-content-wrapper {
			background: none;
			border-radius: 0;
			box-shadow: none;
			padding: 0;

			.leaflet-popup-content {
				margin: 0;
				min-width: 17rem;
				width: auto !important;

				&.leaflet-popup-scrolled {
					border: none;
					border-radius: 0.5rem 0 0 0.5rem;
					box-shadow: $boxShadow;
					@include scrollbar;
				}
			}
		}

		.leaflet-popup-tip {
			background-color: $blue-soft;
		}

		.leaflet-popup-close-button {
			background-color: rgb(0 0 0 / 50%);
			border: 1px solid #fff;
			border-radius: 50px;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			overflow: hidden;
			width: 22px;
			height: 22px;
			top: 10px;
			right: 10px;
			@include standard-transition;

			&:hover {
				background-color: rgb(0 0 0 / 80%);
				color: #fff;
			}
		}
	}

	.leaflet-routing-container {
	}

	.leaflet-routing-collapse-btn {
		top: -1px !important;
		left: 1px !important;
		cursor: pointer;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

  
  .custom-marker-cluster{
	background: $green-delta!important;
	border: 15px solid $green-delta !important;
	border-radius: 50%;
	color: white;
	height: 33px;
	line-height: 37px;
	text-align: center;
	width: 33px;
  }