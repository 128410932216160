.dropdown {
    position: relative;
  
    .dropdown-toggle {
      cursor: pointer;
      font-size: 1rem;
      font-weight:300;
      text-align: left;
      background-color: $green-delta;
      font-family: $fontEuropa;
      color:$white;
        border: none;
        border-radius: 16px;
        gap: 0.5rem;
        height: 32px;
        line-height: 1em;
        padding: 0.4rem 1rem;
        transition: all ease-in-out 0.3s;
        width: auto;
        min-width: 130px;

        svg{
          fill:white;
        }
    }
  
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      padding: 10px;
      background-color: #fff;    
      border-radius: 10px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1607843137);
      top: 52px;
      max-height: -moz-fit-content;
      max-height: fit-content;
      padding: 1rem;
      width: 300px;
    }
  
    .dropdown-menu-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      flex-direction:column;
  
      .dropdown-search {
        padding: 5px;
        border: 1px solid #ccc;
        width: 100%;
        margin:0;
        margin-bottom:.5rem;
      }
  
      .dropdown-actions {
        display: flex;
        align-items: center;
        width:100%;
  
        button {
          margin-right: 10px;
          font-size: 14px;
          padding: 5px 10px;
          border: 1px solid #ccc;
          background-color: #fff;
          cursor: pointer;
          color: #333;
          width:100%;
          margin:0;
          &:hover {
            background-color: #ccc;
          }
        }
      }
    }
  
    .dropdown-options {
      max-height: 250px;
      overflow-y: auto;

      .byway-item{
        font-size: 0.9rem;
        p{
          margin: 0px;
          margin-top:.5rem;
          line-height:1;
          padding: 0px; 
        }
        &.active{
          color:$orange-fiery;
        }
      }
    }
  
    .dropdown-group {
      font-weight: bold;
      margin-bottom: 5px;
    }
  
    .dropdown-option {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      border-radius: 2rem;
        gap: 0.625rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1em;
        padding: 0.25rem;
        white-space: normal;
  
      input[type="checkbox"] {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  


//TABLET + PHONE
@media screen and (max-width: $screen-md) {

  .dropdown-toggle{
		font-size: .8rem !important;
    svg{
        width: 10px;
        height: 10px;
    }
	}
  .dropdown-menu{
      position: fixed !important;
      width: 100% !important;
  }

}
