#walktour-portal {
	overflow: hidden !important;
	> svg {
		max-width: 100%;
		rect {
			fill-opacity: 0.5;
		}
	}
	#walktour-tooltip-container {
		button{
			font-size: .9rem !important;
			font-family: "europa", sans-serif !important;
		}
		> div {
			border-radius: 1.1rem !important;
			font-size: 1rem !important;
			font-family: "europa", sans-serif;
			padding: 0.75rem 1rem 0.5rem !important;
			> div:last-child {
				border-top: 1px solid $green-sky-splash;
				margin-top: 0.5rem !important;
				padding-top: 0.5rem !important;
				> button:first-child {
					border-color: #000 !important;
					color: #000 !important;
				}
				> button:nth-child(2) {
					background-color: $green-sky-splash !important;
					border: none !important;
					color: #000 !important;
				}
				> button:last-child {
					background-color: $green-calyso !important;
					border: none !important;
					color: #fff !important;
				}
				> button:disabled {
					cursor: not-allowed !important;
					opacity: 0.4 !important;
				}
			}
		}
	}
}
