.alert-popup {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.25rem;
	max-width: 650px;
	padding: 1.5rem 2.75rem;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	z-index: 999;

	&.success {
		background: $green-calyso;
	}

	&.warning {
		background: $orange-adobe;
	}

	&.error {
		background: $red;
	}

	span {
		border: 1px solid #fff;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;
		padding: 0.5rem;
		width: 2rem;
		height: 2rem;

		svg {
			fill: #fff;
		}
	}

	p {
		font-size: 1.25rem;
		color: white;
		margin: 0;
	}
}

@media screen and (max-width: $screen-sm) {
	.alert-popup {
		bottom: 1rem;
		gap: 1rem;
		max-width: calc(100vw - 2rem);
		padding: 1.5rem;
		span {
			padding: 0.35rem;
			width: 1.5rem;
			height: 1.5rem;
		}
		p {
			font-size: 1rem;
		}
	}
}
