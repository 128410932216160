.ac-card-wrapper,
.card-wrapper, 
.tt-card-wrapper {
	background-color: $blue-soft;
	border-radius: 0.5rem;
	box-shadow: $boxShadow;
	overflow: visible;

	.card-image {
		height: 170px;
		position: relative;


		.card-figure{
			margin:0;
			padding:0;
			height: 100%;
			width: 100%;
			position:relative;

			img {
				height: 170px;
				object-fit: cover;
				width: 100%;
			}
			
			figcaption{
				position:absolute;
				bottom:0;
				left:0;
				z-index:0;
				width:100%;
				height:auto;
				padding:.25rem;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.category-icon{
					height: auto;
					width:auto;
					margin:0;
					padding:0;
					display: flex;
					justify-content: center;
					align-items: center;
					margin:.125rem;
					position:relative;
					svg{
						width: auto;
						height: 30px;
						background: white;
						border-radius: 50%;
						border: 0px;
					}
				}
			}
		}


		.add-to-cart {
			background-color: rgb(0 0 0 / 50%);
			border: 1px solid #fff;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			bottom: 10px;
			right: 10px;
			@include standard-transition;
			width: 22px;
			height: 22px;

			&:hover {
				background-color: rgb(0 0 0 / 80%);
			}

			&.active {
				background: $orange-fiery;
			}

			svg {
				fill: #fff;
				width: 12px;
			}
		}
	}

	.card-content {
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
		padding: 1.25rem;
		text-align: left;

		h5 {
			font-family: $fontAlbra;
			font-size: 1.25rem;
			font-weight: 700;
			line-height: 1.1em;
			margin: 0;

			a {
				color: $grey-gluon;
			}
		}

		address,
		p,
		li {
			font-family: $fontEuropa;
			font-size: 0.875rem;
			font-style: normal;
			line-height: 1.25em;
			margin: 0;
		}

		a {
			color: $orange-adobe;
			text-decoration: underline;
			&[target="_blank"] {
				position: relative;
				svg {
					fill: $orange-adobe;
					position: absolute;
					margin-left: 0.25rem;
					max-height: 0.65rem;
					width: auto;
				}
			}
		}

		ul {
			margin: 0;
		}
	}
}

.tt-card-wrapper {
	background-color: $blue-soft;
	border-radius: 0.5rem;
	box-shadow: $boxShadow;
	overflow: hidden;

	a {
		position: relative;

		.card-image {
			height: 250px;
			position: relative;

			img {
				transition:all .3s ease-in-out;
				height: 250px;
				object-fit: cover;
				width: 100%;
			}
		}

		.card-content {
			display: flex;
			flex-direction: column;
			gap: 0.75rem;
			padding: 1.25rem;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 125px;
			background: rgb(44 74 74 / 75%);
			color: white;
			justify-content: center;
			text-align: center;
			transition:all .3s ease-in-out;

			h5 {
				font-family: $fontAlbra;
				font-size: 1.125rem;
				font-weight: 700;
				line-height: 1.1em;
				margin: 0;
			}

			address,
			p {
				font-family: $fontEuropa;
				font-size: 0.875rem;
				font-style: normal;
				line-height: 1.25em;
				margin: 0;
			}
		}
		&:hover{
			.card-content {
				height:100%;
			}
			
			.card-image {
				img {
					height: 300px;
				}
			}
		}
	}
}

.twc-card {
	background-color: #fff;
	border: 0.5rem solid #fff;
	display: flex;
	flex-direction: column;
	.card-image {
		background-color: $orange-shea;
		border-radius: 0.5rem 0.5rem 0 0;
		height: calc(170px - 0.5rem);
		img {
			border-radius: 0.5rem 0.5rem 0 0;
			height: calc(170px - 0.5rem);
		}
	}
	.card-content {
		align-items: center;
		background-color: $orange-shea;
		border-radius: 0 0 0.5rem 0.5rem;
		flex: 1;
		gap: 1rem;
		justify-content: center;
		text-align: center;
		.twc-card{
			a{
				color:#fff;
			}
		}
	}
}

/**
Side Panel Specific
**/
.side-panel {
	.ac-card-wrapper {
		background-color: #fff;
		border-radius: 0;
		display: flex;
		flex: 0 0 auto;
		min-height: 9rem;
		position: relative;

		.card-image {
			flex: 1 0 35%;
			height: 100%;
			position: unset;

			.card-figure{
				margin:0;
				padding:0;
				height: 100%;
   				width: 100%;
				position:relative;

				img {
					aspect-ratio: 1;
					height: 100%;
				}

				figcaption{
					position:absolute;
					bottom:0;
					left:0;
					z-index:0;
					width:100%;
					height:auto;
					padding:.25rem;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.category-icon{
						height: auto;
						width:auto;
						margin:0;
						padding:0;
						display: flex;
						justify-content: center;
						align-items: center;
						margin:.125rem;
						svg{
							width: auto;
							height: 30px;
							background: white;
							border-radius: 50%;
							border: 0px;
						}
					}
				}
			}

			.add-to-cart {
				top: 10px;
			}
		}

		.card-content {
			flex: 1 1 100%;
			padding: 1rem;

			h5 {
				padding-right: 1.5rem;
			}
		}
	}
}
