/******
BUTTONS
******/

.btn {
	border: 1px solid black;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 0.75rem;
	padding: 0.75rem 1rem;
	text-align: center;
	@include standard-transition;

	svg {
		height: 0.75rem;
		@include standard-transition;
	}

	&.btn-small {
		font-size: 0.9rem;
		line-height: 1em;
	}

	&.btn-block {
		display: flex;
		width: 100%;
	}

	&.btn-block-50{
		display: flex;
		width:50%;
	}

	&.btn-white {
		background: white;
		color: black;
	}

	&.btn-white-shadow {
		background-color: #fff;
		border: 1px solid #d5d5d5;
		box-shadow: $boxShadow;

		&:hover {
			background: $green-calyso;
			border-color: $green-calyso;
			color: #fff;

			svg {
				fill: #fff;
			}
		}
	}

	&.btn-green {
		background: $green-calyso;
		border-color: $green-calyso;
		color: #fff !important;
	}

	&.btn-orange {
		background: $orange-fiery;
		border-color: $orange-fiery;
		color: #fff !important;
	}

	&:hover {
		opacity: 0.75;
		text-decoration: none;
	}
}

//introjs

.introjs-button {
	background: $orange-fiery !important;
	border-color: $orange-fiery !important;
	color: #fff !important;
	text-shadow: none !important;
	border: 1px solid black !important;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 0.75rem;
	padding: 0.75rem 1rem !important;
	text-align: center;
	@include standard-transition;
}
