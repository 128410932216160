.tooltip {
	position: relative;
	.tooltiptext {
		visibility: hidden;
		background-color: $black;
		color: $white;
		padding: .25rem;
		border-radius: 6px;
		position: absolute;
		z-index: 999;
        bottom:35px;
        left: 0;
        font-size:.7rem;   
	}
	&:hover {
		.tooltiptext {
			visibility: visible;
		}
	}
}
